import React from "react";

function DonationsComponent() {
  return (
    <div className="disp-flex hover-cursor main-donation-btn">
      <div>Donation</div>
    </div>
  );
}

export default DonationsComponent;
