import React from "react";
import "./styles/App.css";

import Page from "./Pages/Page";

function App() {
  return <Page />;
}

export default App;
